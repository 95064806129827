import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";

import "../../scss/main.scss";

export default function Blog() {
	const cookieContainerRef = useRef()
	useEffect(() => {
		let script = document.createElement("script");
		script.id = 'CookieDeclaration'
    	script.src = "https://consent.cookiebot.com/af2eb560-865b-442a-b6a0-60f8dff7846a/cd.js"
		script.type = 'text/javascript'
    	script.async = true;

		cookieContainerRef.current.appendChild(script)
	}, [])
	
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Blog</title>
				<meta property="og:title" content="Edyn Care | Cookies"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/blog" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/blog" />
				<meta name="twitter:title" content="Edyn Care | Cookies" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main className="blog-post">
					<div className="blog-post__above-the-fold">
						<StaticImage
							src="../../static/assets/marketing-site/images/our-approach-01.png"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
						<div>
							<h1 className="blog-post__above-the-fold-title">Cookies</h1>
						</div>
						<StaticImage
							src="../../static/assets/marketing-site/images/our-approach-02.png"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
					</div>
					<section className="blog-post-section">
						<div className="blog-post__content">
							<div ref={cookieContainerRef} />
						</div>
					</section>
				</main>
				<Footer includeCTA={false}/>
			</div>
		</>
	);
}
